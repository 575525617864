import React from "react"
import { Router } from "@reach/router"
import Default from "../components/RtvcDefault"
import Chapter from "../components/RtvcChapter"

const App = () => {
  return (
    <Router>
      <Default path="/app" />
      <Chapter path="/app/:slugChapter"/>
    </Router>
  )
}

export default App
